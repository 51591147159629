import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import MarkOne from '../components/mark-one/mark-one.js';

const IndexPage = () => (
  <Layout>
    <SEO title="Savvy N Sassy" keywords={['photography', 'portfolio', 'savvynsassy']} />
    {/* <SEO title="Home" keywords={['gatsby', 'application', 'react']} />
    <h1>Hi people</h1>
    <p>Welcome to your new Gatsby site.</p>
    <p>Now go build something great.</p>
    <div style={{ maxWidth: '300px', marginBottom: '1.45rem' }}>
      <Image />
    </div>
    <Link to="/page-2/">Go to page 2</Link> */}
    <MarkOne></MarkOne>
  </Layout>
)

export default IndexPage
