import React from 'react';
import styles from '../mark-one/mark-one.module.css';

class MenuItem extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }
    getData(name) {
        this.props.onMenuClick(name);
    }
    render() {
        return (
            <a href="javascript:void(0)" onClick={()=> this.getData(this.props.type)} className={[styles['menu__item']].join(' ')}>{this.props.name}</a>
        );
    }
}
export default MenuItem;
