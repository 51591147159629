import * as React from 'react';
import styles from './imgCell.module.css';
class Imgcell extends React.Component {
    
    constructor(props) {
        super(props);
        this.openFrame = this.openFrame.bind(this);
        console.log(this.state)
    }
    openFrame($e) {}
    render() {
        return(
            <div className={[styles.grid__item, this.props.img.class].join(' ')} data-class={this.props.img.class} data-size="865x1280" style={this.props.img.wraperStyle} onClick={this.props.onClick}>
                <a href="javascript:void(0)" className={styles.img_wrap} onClick={this.openFrame}><img src={this.props.img.img} />
                    <div className={styles.description} style={this.props.img.containerStyle}>
                        <h3>Rabbit Intelligence</h3>
                        <p>{this.props.img.description}</p>
                    </div>
                </a>
            </div>
        );
    }
}

export default Imgcell;