import React, { Component } from 'react';
import Imgcell from '../imageCell/imgCell';
import Isotope from 'isotope-layout';
import Preview from '../previewWrapper/previewWrapper';
import styles from './isotopgrid.module.css';
import store from '../../store/index';
import {connect} from 'react-redux';
import { setMenu } from '../../actions/index';
// Container for isotope grid
class IsotopGrid extends Component {
    
    constructor(props) {
        super(props);
        console.log(store.getState())
        this.gridList = '';
        this.filterType = '';
        this.timer = null;
        this.iso = '';
        this.state = {
            dataList: [
            ],
            activeBox: {
                description: '', 
                title: '',
                id: '',
                elSize:{},
                element:{},
                imgPosition: {
                    x: -0.5,
                    y: 1
                },
                originalSize: {
                    height: 1280,
                    width: 857
                },
                animationStyle:{
                    'heihgt':'',
                    'width': '',
                    'top':'',
                    'left':'',
                    
                },
                previewOpener:[]
            },
            isotope: null
        };
        
        this.triggerClick = this.triggerClick.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        
    }
    
    imageRanderor() {
        return this.state.dataList.map((el, index) => {
            return ( <Imgcell data-key={index} key={index} img={el} onClick={($event) => this.triggerClick(index, $event)}> </Imgcell>);
        })
    }
    _getWinSize() {
		const obj =  {
			width: document.documentElement.clientWidth,
			height: window.innerHeight
        };
        return obj;
	};
    triggerClick(index, event) {
        let state = this.state.dataList;
        let elsize = event.target.getBoundingClientRect();
        let element = event.target;
        
        let win = this._getWinSize();
        console.log(win);
        let x = this.state.activeBox.imgPosition.x;
        let y = this.state.activeBox.imgPosition.y;
        console.log(y);
        console.log(element.offsetHeight);
        console.log(win.height);
        let originalSize = this.state.activeBox.originalSize;
        let dx = ((x > 0 ? 1-Math.abs(x) : Math.abs(x)) * win.width + x * win.width/2) - elsize.left - 0.5 * element.offsetWidth;
        let dy = ((y > 0 ? 1-Math.abs(y) : Math.abs(y)) * win.height + y * win.height/2) - elsize.top - 0.5 * element.offsetHeight;
        let dyMob = ((y > 0 ? 1-Math.abs(y) : Math.abs(y)) * win.height + y * win.height/2) - elsize.top - 0.8 * element.offsetHeight;
        let z = Math.min( Math.min(win.width*Math.abs(x), originalSize.width) / element.offsetWidth, Math.min(win.height*Math.abs(y), originalSize.height)/element.offsetHeight );
        
        
        state = state.map((options, ind) => {
            if (index !== ind) {
                options.wraperStyle = {
                    transition: 'opacity 0.5s cubic-bezier(0.7, 0, 0.3, 1) 1ms, transform 0.5s cubic-bezier(0.7, 0, 0.3, 1) 1ms',
                    transform: 'scale3d(0.1, 0.1, 1)',
                    opacity: '0'
                }
                options.containerStyle = {
                    display: 'block'
                }
            } else {
                const url = options.img;
                let mainImg = options.mainImg;
                this.setState({
                    'activeBox': {
                        'description': options.description,
                        'camera': options.camera,
                        'lense': options.lense,
                        'aperture': options.aperture,
                        'speed': options.speed,
                        'iso': options.iso,
                        'auther': options.auther,
                        'title':'',
                        'id': index,
                        'imgUrl': url,
                        'mainImg': mainImg, 
                        'elSize': event.target.getBoundingClientRect(),
                        'element':event.target,
                        imgPosition: {
                            x: -0.5,
                            y: 1
                        },
                        'animationStyle':{
                            'heihgt': element.offsetHeight,
                            'width': element.offsetWidth,
                            'top': event.target.getBoundingClientRect().top,
                            'left': event.target.getBoundingClientRect().left,
                            
                        },
                        originalSize: {
                            height: 1280,
                            width: 857
                        },
                        'previewOpener':['preview__open']
                    }
                    
                });
            }
            return options;
        });
        this.setState({
            dataList: state
        },()=>{
            let stMain = Object.assign({}, this.state.activeBox);
            let st = Object.assign({}, this.state.activeBox.animationStyle);
            if(win.width < 400){
                Object.assign(st, 
                    {
                        'WebkitTransform': 'translate3d(0, '+dyMob+'px, 0) scale3d(1.400, 1.400, 1)',
                        'transform': 'translate3d(0, '+dyMob+'px, 0) scale3d(1.400, 1.400, 1)'
                    }
                );
            }else {
                Object.assign(st, 
                    {
                        'WebkitTransform': 'translate3d(' + dx + 'px, ' + dy + 'px, 0) scale3d(' + z + ', ' + z + ', 1)',
                        'transform': 'translate3d(' + dx + 'px, ' + dy + 'px, 0) scale3d(' + z + ', ' + z + ', 1)'
                    }
                );
            }
            
            stMain['animationStyle'] = st;
            // st.animationStyle['WebkitTransform'] = 'translate3d(' + dx + 'px, ' + dy + 'px, 0) scale3d(' + z + ', ' + z + ', 1)';
            // st.animationStyle['transform']  = 'translate3d(' + dx + 'px, ' + dy + 'px, 0) scale3d(' + z + ', ' + z + ', 1)';
            this.setState({
                activeBox: stMain
            })
        });
        
    }
    onCloseModal() {
        let state = this.state.dataList;
        let activeboxData = this.state.activeBox;
        state = state.map((options, index) => {
            if (index !== activeboxData.id) {
                options.wraperStyle = {
                    transition: 'none 0s ease 0s',
                    transform: 'none', 
                    opacity: '1'
                }
                options.containerStyle = {
                    display: 'none'
                }
            } else {
                this.setState({
                    activeBox: {
                        previewOpener:[],
                        description: '', 
                        title: '',
                        id: '',
                        elSize:{},
                        element:{},
                        imgPosition: {
                            x: -0.5,
                            y: 1
                        },
                        originalSize: {
                            height: 1280,
                            width: 857
                        },
                        animationStyle:{},
                    }
                });
                
            }
            return options;
        });
        this.setState({
            dataList: state
        });
    }
    resetGrid(color) {
        this.iso.arrange()
        // this.filterType = color;
        if(color !== 'all') {
            this.iso.arrange({ filter: '.'+color });
        }else {
            this.iso.arrange({ filter: '' });
        }
        //this.iso.layout();
    }
    render() {
        return(
            <div >
                <div ref={input => {this.gridList = input;}} className={styles['my-gallery-class']}>
                    {this.imageRanderor()} 
                </div>
                <Preview options={this.state.activeBox} closeModalBox={this.onCloseModal}></Preview> 
            </div>
        )
    }

    // set up isotope
    componentDidMount() {
        const API = 'https://sheets.googleapis.com/v4/spreadsheets/18SptmDdjp47YHXiMgs26ChMe-F-F8A66VNADs6ogsYI/values:batchGet?ranges=Sheet1&majorDimension=ROWS&key=AIzaSyBjJeJStHTAtedcXJeP9RDM_Wr8-jiIcrI';
        
        fetch(API).then(response => response.json()).then(data => {
            let batchRowValues = data.valueRanges[0].values;
            const dataSet = [];
            console.log(batchRowValues);
            let i = 0
            for(let row of batchRowValues.reverse()) {
                if(i !== batchRowValues.length - 1) {
                    let Obj = 
                    {
                        img: row[0],
                        mainImg: row[1],
                        wraperStyle: {
                            transition: 'none',
                            transform: 'none',
                            opacity: '1',
                            
                        },
                        class: row[9],
                        containerStyle: {
                            display: 'none'
                        },
                        description: row[7],
                        camera: row[2],
                        lense: row[3],
                        aperture: row[4],
                        speed: row[5],
                        iso: row[6],
                        auther: row[8],
                    };
                    dataSet.push(Obj);
                }
                i++;
                
            }
            
            this.setState({
                dataList: dataSet
            });
            setTimeout(()=> {
                this.iso.arrange();
            }, 200)
        });
        if (!this.iso) {
            
                this.iso = new Isotope( this.gridList, {
                    layoutMode: 'masonry',
                    masonry: { 
                        isFitWidth: true 
                    }
                }); 

                window.addEventListener('scroll', this.handleScroll, false);
            
            // this.iso.arrange();
        } else {
            this.iso.reloadItems();
        }
    }
    handleScroll() {
        if(this.timer !== null) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(()=>{
            // this.iso.arrange();
        }, 150);
    }
    // update isotope layout
    componentDidUpdate() {
        let imgType = store.getState().rootReducer.currentMenu;
        this.resetGrid(imgType);
        if (this.iso) {
            this.iso.reloadItems();
            this.iso.layout();
        }
    }
}
const mapStateToProps = state => ({
    rootReducer: state.rootReducer,
    itemReducer: state.itemReducer 
});
const mapActionsToProps = {
    onSetMenu: setMenu
}
export default connect(mapStateToProps, mapActionsToProps)(IsotopGrid);
