import * as React from 'react';
import styles from './previewWrapper.module.css';
class Preview extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            options: {
                animationStyle: {},
                mainImgclass:['original']
            }
        };
        this.wraperStyle = []
        this.endOfTransition = this.endOfTransition.bind(this);
        this.resetAnimationState = this.resetAnimationState.bind(this);
    }
    
    componentWillReceiveProps() {
       
    }
    
    endOfTransition() {
        
        let sttt = this.state.options;
        if(sttt.animationStyle.WebkitTransform === 'translate3d(0,0,0) scale3d(1,1,1)') {
            sttt.animationStyle = {};
            this.wraperStyle.push(styles['preview--image-loaded'])
            this.setState({
                options: sttt
            });
            this.props.closeModalBox();
        }else {
            console.log('afkdfjkafj')
            console.log(this.props)
            let afterAnimation = this.state.options;
            
            afterAnimation.mainImgclass.push('originalActive');
            this.setState({
                options: afterAnimation
            });
            console.log(this.state.options);
            
        }
    }
    resetAnimationState() {
        let stt = this.state.options;
        stt.mainImgclass = ['original'];
        stt.animationStyle = {
            'WebkitTransform': 'translate3d(0,0,0) scale3d(1,1,1)',
            'transform': 'translate3d(0,0,0) scale3d(1,1,1)',
            'height': this.props.options.elSize.height,
            'width': this.props.options.elSize.width,
            'left': this.props.options.elSize.left,
            'top': this.props.options.elSize.top
        };
        this.setState({
            options: stt
        });
        setTimeout( () => {
            this.endOfTransition();
        }, 250);
        //
        console.log(this.state.options)
        
    }
    render() {
        
        this.wraperStyle = [styles.preview];
        const prevewClass = this.props.options.previewOpener.map(ar => styles[ar]);
        this.wraperStyle.push(prevewClass);

        let mainImg = this.state.options.mainImgclass.map(cls => styles[cls]);
        //const prevewClass = this.props.options.previewOpener.join(', ');
        return (
            <div className={this.wraperStyle.join(' ').replace(',', ' ')}>
                <button className={[styles.action, styles.action__close].join(' ')} onClick={this.resetAnimationState}>x</button>
                <div className={[styles.description, styles.description__preview].join(' ') }>
                    {this.props.options.description}
                    <em>&mdash; {this.props.options.auther}</em>
                    <div className={styles.details}>
                        <div>
                            <ul>
                                <li><i className={[styles['icon'], styles['iconCamera']].join(' ')}></i><span>{this.props.options.camera}</span></li>
                                <li><i className={[styles['icon'], styles['iconFocal_length']].join(' ')}></i><span>{this.props.options.lense}</span></li>
                                <li><i className={[styles['icon'], styles['iconAperture']].join(' ')}></i><span>&fnof;/{this.props.options.aperture}</span></li>
                                <li><i className={[styles['icon'], styles['iconExposure_time']].join(' ')}></i><span>{this.props.options.speed}</span></li>
                                <li><i className={[styles['icon'], styles['iconIso']].join(' ')}></i><span>{this.props.options.iso}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <img id="original" className={mainImg.join(' ')} src={this.props.options.mainImg} />
                <img id="clone" onTransitionEnd={this.endOfTransition} style={(Object.keys(this.state.options.animationStyle).length === 0)?this.props.options.animationStyle:this.state.options.animationStyle} className={(this.props.options.imgUrl)?styles.clone:''} src={this.props.options.imgUrl} />
                {/* <img className={styles.original} src={this.props.options.imgUrl} /> */}
            </div>
        )
    }
}

export default Preview;