import React from 'react';
import classNames from 'classnames';
import styles from './mark-one.module.css';
import IsotopGrid from '../isotop/isotopGrid';
import MenuItem from '../menu/menu';
import {connect} from 'react-redux';
import { setMenu } from '../../actions/index';
import Image from '../image';
class MarkOne extends React.Component {
    constructor() {
        super();
        this.menuList = this.menuList.bind(this);
        this.menuClick = this.menuClick.bind(this);
    }
    menuClick(name) {
        this.props.onSetMenu(name);
    }
    menuList() {
        return Object.keys(this.props.rootReducer.menus).map((el, index) => {
            return (<MenuItem key={index} name={this.props.rootReducer.menus[el].name} type={this.props.rootReducer.menus[el].type} onMenuClick={(name) => this.menuClick(name)}></MenuItem>);
        });
    }
    
    render() {
        let cx = classNames.bind(styles);
        return (
            <div className={[styles['js']].join(' ')}>
                <svg className={[styles['hidden']].join(' ')}>
                    <symbol id="icon-arrow" viewBox="0 0 24 24">
                        <title>arrow</title>
                        <polygon points="6.3,12.8 20.9,12.8 20.9,11.2 6.3,11.2 10.2,7.2 9,6 3.1,12 9,18 10.2,16.8 "/>
                    </symbol>
                </svg>
                <main>
                    <h2 className={cx(styles['title'])}><a href="#content-main">Savvy N Sassy</a></h2>
                    <nav className={[styles['menu']].join(' ')}>
                        {this.menuList()}
                        {/* <a href="#content-photography" className={[styles['menu__item']].join(' ')}>Photography</a>
                        <a href="#content-exhibitions"  className={[styles['menu__item']].join(' ')}>Exhibitions</a>
                        <a href="#content-contact"  className={[styles['menu__item']].join(' ')}>Contact</a> */}
                    </nav>
                    <div className={[styles['content'], styles['contentMain'], styles['contentCurrent']].join(' ')} id="content-main">
                        <div className={[styles['content__text']].join(' ')}>
                        <p className={[styles['content__paragraph']].join(' ')}>Hello stranger, my name is <a href="#">Deepak</a> and I'm a <a href="#">photographer</a> from India. <br/>I'm also a passionate long distance runner with a weakness for <a href="#">Good Books</a>. Its very little about me, To know more follow me here.</p>
                        <div className={[styles['social']].join(' ')}>
                            <span className={[styles['social__title']].join(' ')}>Email</span>
                            <a href="#" className={[styles['social__link']].join(' ')}>mailtodeepak.me@gmail.com</a>
                        </div>
                        <div className={[styles['social']].join(' ')}>
                            <span className={[styles['social__title']].join(' ')}>Instagram</span>
                            <a href="https://www.instagram.com/deepak.datwani/" className={[styles['social__link']].join(' ')}>Deepak_datwani</a>
                        </div>
                        </div>
                        <aside className={[styles['content__sidebar']].join(' ')}>
                            <section className={styles['related']}>
                                <IsotopGrid></IsotopGrid>
                            </section>
                        </aside>
                    </div>
                </main>
            </div>
        );
    }
    componentDidUpdate() {
        // console.log(store.getState());
    }
}
const mapStateToProps = state => ({
    rootReducer: state.rootReducer,
    itemReducer: state.itemReducer 
});
const mapActionsToProps = {
    onSetMenu: setMenu
}
export default connect(mapStateToProps, mapActionsToProps)(MarkOne);